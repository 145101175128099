import React from 'react'
import Header from '../Header/Header'
import './Hero.css'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'

 const Hero = () => {
    const transition={type:'spring', duration:3}
    const mobile=window.innerWidth<=768?true: false
  return (
    <div className="hero">
        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
            {/* Best ad */}
            <div className="the-best-ad">
                <motion.div initial={{left:mobile?"165px":"238"}}
                  whileInView={{left:'8px'}} transition={{...transition,type:'tween'}}>
                    
                   
                    
                </motion.div>
                <span>authentic importer and supplier</span>
            </div>

            {/* Hero heading */}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>HS plus </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>
                    Ideal Patner
                    </span>
                </div>
                <div >
                    <span>
                    Hs Plus is a well-known LED light and Electric Products importer and supplier in Bangladesh.
                    </span>
                </div>
            </div>
            {/* figures */}

            <div className="figures">
                <div><span>+40</span><span>Items </span></div>
                <div><span>+998</span><span>Clients </span></div>
                <div><span>+6</span><span>Years</span></div>
            </div>
            {/* hero btn */}
            <div className="hero-buttons">
                <button className="btn">Get Started</button>
                <button className="btn">Learn More</button>
            </div>


        </div>
        <div className="right-h">
            <button className="btn">☏ +880 1728-740052</button>
            <motion.div className="heart-rate" initial={{right:'-1rem'}} whileInView={{right:'4rem'}} transition={transition}>
                <img src={Heart} alt="Heart" />
                <span> Enargy Save </span><span>90%</span>
            </motion.div>
            {/* hero images */}
            <img src={hero_image} className="hero-image" alt="hero_image"  />
            <motion.img 
            initial={{right:'11rem'}}
            whileInView={{right:"20rem"}}
            transition={transition}
            src={hero_image_back} className="hero-image-back" alt="hero_image_back"  />

            {/* calories */}
            <motion.div initial={{right:"37rem"}}
            whileInView={{right:"28rem"}}
            transition={transition}

             className="calories">
                <img src={Calories} alt="Calories" />
                <div>
                    <span>Power saving</span>
                    <span>LED</span>
                </div>
            </motion.div>
        </div>
    </div>
  )
}

export default Hero;
