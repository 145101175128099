export const programsData = [
  {
    image:  `https://i.ibb.co/xLyBSpt/IMG-20230606-082301.png"`,
    heading: "HIGH QUALITY SWITCH ",
    details:
      "HIGH QUALITY SWITCH",
  },
  
  {
    image:  `https://i.ibb.co/mB4gt8x/IMG-20210331-092631.png`,
    heading: "AC/DC LED Light : 12W/5W ",
    details:
      "AC/DC LED Light :12W/5W",
  },

  {
    image:  `https://i.ibb.co/JFY1JgS/IMG-20230606-083305-as-Smart-Object-1.png`,
    heading: "AC LED Light : 5W-7W-9W-12W-16W-23W ",
    details:
      "AC LED Light : 5W-7W-9W-12W-16W-23W",
  },


 
 
];



