import image1 from "../assets/InShot_20230618_144958565.jpg";


export const testimonialsData = [
  {
    image: image1,
    review:
      "Founder and CEO ",
    name: '',
    status : 'ENTREPRENEUR'
  },

];
