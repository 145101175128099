import React from 'react'
import './Programs.css'
import {programsData}from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'

function Programs() {
  return (
    <div className="programs" id="programs">
        {/* header */}
        <div className="programs-header">
            <span className='stroke-text'>Explore our</span>
            <span>Products</span>
            <span className='stroke-text'>to light you life</span>
        </div>
        <div className="programs-categories">
            {programsData.map((program)=>(
                <div className="category">
                   <img alt='' src={program.image}></img> 
                    <span>{program.heading}</span>
                    {/* <span>{program.details}</span> */}
                    <div className='join-now'>
                        {/* <span>Join Now</span>
                        <img src={RightArrow} alt="RightArrow" /> */}
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Programs