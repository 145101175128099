import React from 'react'
import './Reason.css'
import image1 from '../../assets/image1.jpg'
import image2 from '../../assets/image2.jpg'
import image3 from '../../assets/image3.jpg'
import image4 from '../../assets/image4.jpg'
import adidas from '../../assets/adidas.png'
import nike from '../../assets/nike.png'
import nb from '../../assets/nb.png'
import tick from '../../assets/tick.png'

function Reason() {
  return (
    <div className="reasons" id="reasons">
        <div className="left-r">
            <img src={image1} alt="image1" />
            <img src={image2} alt="image2" />
            <img src={image3} alt="image3" />
            <img src={image4} alt="image4" />
        </div>
        <div className="right-r">
           <span>some reasons</span>
           <div>
            <span className='stroke-text'>Why</span>
           <span> choose us?</span>
           </div>

           <div className='details-r'>
                <div><img src={tick} alt=""></img>
                    <span> OVER 1000+ HAPPY CUSTOMERS</span>
                </div>
                <div><img src={tick} alt="" />
                    <span>high quality products</span>
                </div>
                <div><img src={tick} alt="" />
                    <span>15 mONTHS REPLACEMENT GUARANTEE</span>
                </div>
                <div><img src={tick} alt="" />
                    <span>RELIABLE PARTNERS</span>
                </div>
           </div>
           <span style={{color:"var(--gray)",fontWeight:"normal",}}>
            OUR PARTNER
            </span>
            <div className="partners">
                {/* <img src={nb} alt="" /> */}
                <img src={nike} alt="" />
                {/* <img src={adidas} alt="" /> */}
            </div>
        </div>
    </div>
  )
}

export default Reason